/*----------------------------------------------------------------------------*
	$FORMULARIOS
*----------------------------------------------------------------------------*/



/* ^Form global
-----------------------------------------------------------------------------*/

	$('.js-form').each(function(index, element) {

		var form_global = $(element),
			form_btn = form_global.find('.js-btn-send'),
			form_action = form_global.attr('data-action');

		// Validar campos del formulario Newsletter
		form_global.validate({
			ignore: ".ignore",
			errorClass: 'o-form-error',
			errorPlacement: function(error, element) {
				error.appendTo(element.parents('.js-show-error'));
			},
			// rules: {
			// },
			submitHandler: function(form) {

				form_btn.attr("disabled",true);

				var ip, ciudad, region, pais;

				$.get('https://ipinfo.io/json?token=5d44e9a3d3f86f', function( r ){
					ip = r.ip;
					ciudad = r.city;
					region = r.region;
					pais = r.country;
				}).done(function(){

					data_geo = '&ip='+ip;
					data_geo += '&ciudad='+ciudad;
					data_geo += '&region='+region;
					data_geo += '&pais='+pais;

					form_data = form_global.serialize() + data_geo;

					// Ocultar las notificaciones al presionar boton enviar
					$('.js-notificacion').slideUp(400);

					// Mostrar cargador
					open_loading();


					$.post(form_action, form_data,
						function(data)
							{

								form_btn.removeAttr('disabled');

								// Si se envió correctamente
								if(data.match('1') == '1'){

									$('.js-notificacion').slideDown(400);
									$('.js-form-inputs').slideUp(400);

									$('.o-form-field, .o-form-field--textarea').val("");
									$('[type="checkbox"]').attr("checked", false);

									// Ocultar cargador
									close_loading();


									// fbq('track', 'CompleteRegistration', {currency: "COP", value: 0.00});
									//
									// gtag('event', 'conversion', {'send_to': 'AW-10822339793/aikpCNCy-ogDENGZv6go'});


								}

							}
					);
					// Fin - $.post()

				});


			}
		});

	});


/* ^Fin - Form global
-----------------------------------------------------------------------------*/





/* ^Click WhatsApp
-----------------------------------------------------------------------------*/

	$('.o-btn-whatspp__container').on('click', function(e) {
		// fbq('trackCustom', 'ClickWhatsApp');
		//
		// gtag('event', 'conversion', {'send_to': 'AW-10822339793/JZQNCKTqyogDENGZv6go'});
	});

/* ^Fin - Click WhatsApp
-----------------------------------------------------------------------------*/





/* ^Click Llamada
-----------------------------------------------------------------------------*/

	$('.js-btn-llamar').on('click', function(e) {
		// fbq('trackCustom', 'ClickLlamada');
		//
		// gtag('event', 'conversion', {'send_to': 'AW-10822339793/LxSJCMTN9YgDENGZv6go'});
	});

/* ^Fin - Click Llamada
-----------------------------------------------------------------------------*/
