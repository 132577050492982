/*----------------------------------------------------------------------------*
	$POPUPS.JS
*----------------------------------------------------------------------------*/


/* ^Loading modal
-----------------------------------------------------------------------------*/

	function open_loading() {
		$('.js-menu-mobile-overlay').css('z-index',5000).fadeIn(200);
		$('.js-loader').css('z-index',5001).fadeIn(400);
	}

	function close_loading() {
		$('.js-menu-mobile-overlay').fadeOut(200, function(){
			$(this).css('z-index',100)
		});
		$('.js-loader').fadeOut(400, function(){
			$(this).css('z-index',101)
		});
	}

/* ^Fin - Loading modal
-----------------------------------------------------------------------------*/





/* ^popUp Global
-----------------------------------------------------------------------------*/

	// Cerrar popUp
	$('.js-close-popup').on('click', function(){
		$.magnificPopup.close();
	});

	// Antes de abrir el popUp
	function beforeOpenPopup(){

		// $('body').addClass('c-popup-open');
		$('.js-header').css("width", "calc(100% - " + scrollbarSize + "px)");

	}

	// Al cerrar el popUp
	function closePopup(){

		// $('body').removeClass('c-popup-open');
		$('.js-header').css("width", "100%");

	}

/* ^Fin - popUp Global
-----------------------------------------------------------------------------*/





/* ^Abrir popUps
-----------------------------------------------------------------------------*/

	function data_popup(){

		$('[data-popup]').each(function( index, element ) {

			var id = $(element).attr('data-id');

			// console.log(id);

			$('[data-id="'+id+'"]').on('click', function(e){
				e.preventDefault();

				var id = $(this).attr('data-popup');

				// console.log('abrió popup');

				popup_global(id);
			});

		});


	}
	data_popup();


	function data_popup_ajax(){

		$('[data-popup-ajax]').each(function( index, element ) {

			var id = $(element).attr('data-id');

			// console.log(id);

			$('[data-id="'+id+'"]').on('click', function(e){
				e.preventDefault();

				console.log('entre popup');

				var url = $(this).attr('data-popup-url');

				// console.log('abrió popup AJAX');

				$('#data-info').html('<img class="c-popup__loading" src="/images/loading.gif", alt="Loading">');

				popup_global_ajax(url, '#popup-ajax', '#data-info');
			});

		});


		$('[data-popup-ajax-2]').each(function( index, element ) {

			var id = $(element).attr('data-id');

			// console.log(id);

			$('[data-id="'+id+'"]').on('click', function(e){
				e.preventDefault();

				var url = $(this).attr('data-popup-url');

				// console.log('abrió popup AJAX');

				$('#data-info-2').html('<img class="c-popup__loading" src="/images/loading.gif", alt="Loading">');

				popup_global_ajax(url, '#popup-ajax-2', '#data-info-2');
			});

		});

	}
	data_popup_ajax();


	$('#open').trigger('click');

/* ^Fin - Abrir popUps
-----------------------------------------------------------------------------*/





/* ^popUp Global
-----------------------------------------------------------------------------*/

	function popup_global(id){

		$.magnificPopup.open({

			items: {
				src: id,
				type:'inline'
			},
			removalDelay: 500,
			showCloseBtn: false,
			overflowY: 'auto',
			modal: true,

			// Clase para agregar animación al popUp
			mainClass: 'mfp-zoom-out  mfp-popUp',

			callbacks: {

				beforeOpen: function(){

					beforeOpenPopup();

					// Ocultar notificacion antes de abrir popup
					$('.js-notificacion-newsletter').css('display', 'none');

				},
				open: function(){
				},

				beforeClose: function(){
				},
				close: function(){

					closePopup();

				}

			}
			// Fin - callbacks

		});

	}



/* ^Fin - popUp Global
-----------------------------------------------------------------------------*/





/* ^popUp Global ajax
-----------------------------------------------------------------------------*/


	function popup_contenido_ajax(url, id_data_info){

		var id = $(id_data_info);

		$(id).load(url, function(){
			// step_tabs('.js-step-info');
		});

	}

	function popup_global_ajax(url, size, id_data_info){

		$.magnificPopup.open({

			items: {
				src: size,
				type:'inline'
			},
			removalDelay: 500,
			showCloseBtn: false,
			overflowY: 'auto',
			modal: true,

			// Clase para agregar animación al popUp
			mainClass: 'mfp-zoom-out  mfp-popUp',

			callbacks: {

				beforeOpen: function(){

					beforeOpenPopup();

					popup_contenido_ajax(url, id_data_info);

				},
				open: function(){
				},

				beforeClose: function(){
				},
				close: function(){

					closePopup();

				}

			}
			// Fin - callbacks

		});

	}



/* ^Fin - popUp Global ajax
-----------------------------------------------------------------------------*/
