/*----------------------------------------------------------------------------*
	$MENUS.js
*----------------------------------------------------------------------------*/


/*	^Detectar ancho de scroll
-------------------------------------------------------------*/

	var scrollDiv = document.createElement("div");
	scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';

	document.body.appendChild(scrollDiv);

	scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
	document.body.removeChild(scrollDiv);


/*	^Fin - Detectar ancho de scroll
-------------------------------------------------------------*/





/* ^Open main menú
-----------------------------------------------------------------------------*/

	$('.js-open-menu-mobile').on('click', function(){

		if($('.js-nav-mobile').hasClass('open')){
			$('.js-nav-mobile').removeClass('open');

			$(this).addClass('icon-menu7');
			$(this).removeClass('icon-cross2');
		}
		else {
			$('.js-nav-mobile').addClass('open');

			$(this).removeClass('icon-menu7');
			$(this).addClass('icon-cross2');
		}

	});

	// $('.js-open-menu-mobile').trigger('click');

/* ^Fin - Open main menú
-----------------------------------------------------------------------------*/





/* ^Scrollbar Menu mobile
-----------------------------------------------------------------------------*/

	if( $('.js-scrollbar').length ){
		var ps = new PerfectScrollbar('.js-scrollbar');
	}

/* ^Fin - Scrollbar Menu mobile
-----------------------------------------------------------------------------*/
