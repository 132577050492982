/*----------------------------------------------------------------------------*
	$TURBOLINKS
*----------------------------------------------------------------------------*/

/* ^Transición entre páginas
-----------------------------------------------------------------------------*/

	/*
	$(document).on('turbolinks:request-start', function() {
		$('.info').addClass('fadeout');
	})

	$(document).on('turbolinks:render', function() {
		$('.info').addClass('fadein');
	})
	*/

/* ^Fin - Transición entre páginas
-----------------------------------------------------------------------------*/
